import { Box, Button, Stack, Typography } from "@mui/material";
import 'react-circular-progressbar/dist/styles.css';
import BirthdayProp from "../interfaces/BirthdaytProp";
import ReadingBooks from "./tips/ReadingBooks";
import Running from "./tips/Running";
import ReactGA from "react-ga4";


import LifeSpanCountdown from "./LifeSpanCountdown";
import { remainingLifespan } from "../utils/Birthday";
import PlayingMusic from "./tips/PlayingMusic";
import SavingMoney from "./tips/SavingMoney";
import BigLogo from "./BigLogo";


const TryAgainButton = ({ birthdayChanger }: BirthdayProp) => {
    return (<Button variant="contained"
        sx={{
            backgroundColor: "rgba(255,255,255,0.90)",
            color: "palevioletred"
        }}
        onClick={() => {
            birthdayChanger(null);
            ReactGA.event("try_again");;
        }
        }>
        <Typography fontWeight={500} fontFamily={'D2Coding'}>
            다른 생일 입력해보기
        </Typography>
    </Button>);
}


const Result = ({ birthday, birthdayChanger }: BirthdayProp) => {

    const theLastDay = birthday?.add(100, "year");
    const totalRemainingLifespan = remainingLifespan(theLastDay);

    return (
        <Box maxWidth={450}
            minHeight="100vh"
            display="flex"
            flexDirection="column"
            alignItems="center"
            // justifyContent="center"
            paddingTop={4}
            paddingBottom={4}
        >
            <Box position={"absolute"} top={"100px"}>
                <BigLogo />
            </Box>
            <Box height={180}></Box>
            <LifeSpanCountdown birthday={birthday} birthdayChanger={birthdayChanger} />
            <Box height={50}></Box>
            <TryAgainButton birthday={birthday} birthdayChanger={birthdayChanger} />
            {/* 🧞‍♂️ */}
            <Box height={50}></Box>
            <Typography fontSize={90} sx={{ textShadow: '1px 1px 2px black' }}>👇👇👇</Typography>
            <Stack spacing={3} padding={3} sx={{ marginBottom: 3 }}>
                <ReadingBooks remainingHours={totalRemainingLifespan / 60 / 60} />
                <Running remainingHours={totalRemainingLifespan / 60 / 60} />
                <PlayingMusic remainingHours={totalRemainingLifespan / 60 / 60} />
                <SavingMoney remainingHours={totalRemainingLifespan / 60 / 60} />
                {/* <Programming remainingHours={totalRemainingLifespan / 60 / 60} /> */}
            </Stack>
            <TryAgainButton birthday={birthday} birthdayChanger={birthdayChanger} />
            <Box height={10}>
            </Box>
        </Box>

    );
};

export default Result;
