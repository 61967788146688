import { Typography } from "@mui/material";
import LifeSpanProp from "../../interfaces/LifeSpanProp";
import WomanPlayingGuitar from "../../imgs/tips/playing_guitar_by_chase-yi-min.jpeg"
import LifeTipTemplate from "./LifeTipTemplate";

const description = (remainingHours: number) => {
    const age = (100 - remainingHours / 24 / 365);
    const startingMent = age > 30 ? "악기를 배우는데 늦은 나이는 없습니다." : "";
    
    return (<Typography textAlign={"left"}>
        {startingMent} 걱정마세요 생각보다 어렵지 않습니다.
        한가지 악기를 1년 동안 꾸준히 연습하면 어느정도 익숙해지고, 2년이 지나면 능숙하게 연주하는 곡도 하나둘 생긴다고 합니다.
    </Typography>);
}

export const isPlayingMusicAvailable = (remainingHours: number) => {
    const remainingYears = remainingHours / 24 / 365;
    return remainingYears > 2;
}

const PlayingMusic = ({ remainingHours }: LifeSpanProp) => {
    const contents = isPlayingMusicAvailable(remainingHours) ? <LifeTipTemplate
        tipName="playing_music"
        description={description(remainingHours)}
        buttonDescription="독학으로 기타를 배우는 방법 Best 5"
        buttonUrl="https://digit2sight.com/%EC%B5%9C%EA%B3%A0%EC%9D%98-%EA%B8%B0%ED%83%80-%EB%8F%85%ED%95%99-%EC%97%B0%EC%8A%B5-%EC%95%B1-best-5/"
        image={WomanPlayingGuitar}
        imagePositionY="center" /> : null;
    return (contents);
};

export default PlayingMusic;