import { Typography } from "@mui/material";
import LifeSpanProp from "../../interfaces/LifeSpanProp";
import PiggyBank from "../../imgs/tips/saving_money_insung-yoon-min.jpg"
import LifeTipTemplate from "./LifeTipTemplate";

const calculateFutureValue = (moneyPerMonth: number, months: number, annualInterestRate: number) => {
    const monthlyInterestRate = annualInterestRate / 12;
    let futureValue = 0;
    for (let i = 1; i <= months; i++) {
        futureValue += Math.floor(moneyPerMonth * (1 + (monthlyInterestRate * (i))));
    }
    return futureValue;
}

const TotalDeposit = (moneyPerMonth: number, months: number) => {
    return moneyPerMonth * months;
}

const description = (remainingHours: number) => {
    const totalMonths = Math.ceil(remainingHours / 24 / 31);
    const moneyPerMonth = 100_000;
    const annualInterestRate = 0.045;

    const totalMoneyBeforeTax: number = Math.floor(calculateFutureValue(moneyPerMonth, totalMonths, annualInterestRate));
    const totalDeposit: number = TotalDeposit(moneyPerMonth, totalMonths);
    const totalInterest: number = totalMoneyBeforeTax - totalDeposit;

    const taxRate = 0.154;
    const totalTax = Math.floor(totalInterest * taxRate);

    const totalMoneyAfterTax = totalMoneyBeforeTax - totalTax;
    const earningRateAfterTax = (totalInterest - totalTax) / totalDeposit;

    console.log(totalMonths);
    return (<Typography textAlign={"left"}>
        연 {(annualInterestRate * 100).toFixed(1)}%의 이자를 주는 적금 상품에 오늘부터 매달 {moneyPerMonth.toLocaleString()}원씩 저축한다면 100세 생일까지 얼마를 모을 수 있을까요?         
        15.4%의 세금을 제외하고 총 <span className="result_key_numbers">{totalMoneyAfterTax.toLocaleString()}원</span>을 받을 수 있습니다.
        세후 수익률은 <span className="result_key_numbers">{(earningRateAfterTax * 100).toFixed(2)}%</span>군요. (단리 적금으로 계산시)
    </Typography>);
}

const SavingMoney = ({ remainingHours }: LifeSpanProp) => {
    return (
        <LifeTipTemplate
            tipName="saving_money"
            description={description(remainingHours)}
            buttonDescription="납입 금액 바꿔서 계산해볼 수도 있어요"
            buttonUrl="https://knowingasset.com/app/calculator/savings"
            image={PiggyBank}
            imagePositionY="center" />
    );
};

export default SavingMoney;