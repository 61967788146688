import dayjs from "dayjs";

export function validateBirthday(birthday: dayjs.Dayjs): boolean {
    const now = dayjs();
    const minDate = now.subtract(100, "year");
    return birthday.isAfter(minDate) && birthday.isBefore(now);
}


export function remainingLifespan(theLastday: dayjs.Dayjs | undefined): number {
    if (theLastday === undefined) {
        return NaN;
    }
    return theLastday.diff(dayjs(), "second");
}