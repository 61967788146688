
import { Box, Typography } from '@mui/material'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

interface RemainingLifeProgressProp {
    remainingTimeRatio: number
}

const RemainingLifeProgress = ({ remainingTimeRatio }: RemainingLifeProgressProp) => {
    return (
        <Box width={"120px"} height={"120px"} marginBottom={2}>
            <CircularProgressbarWithChildren
                value={remainingTimeRatio * 100.0}
                counterClockwise={true}
                strokeWidth={50}
                styles={buildStyles({
                    strokeLinecap: 'butt',
                    textSize: 70,
                    pathTransitionDuration: 1,
                    pathColor: `palevioletred`,
                    trailColor: 'lightslategray',
                })} >
                <Typography sx={{ fontSize: 45, textShadow: '0px 2px 5px darkslategray' }}>
                    {/* ⏳ */}
                    {/* ⏱️ */}
                    {/* ⏰ */}
                </Typography>
                <Typography
                    fontFamily={'D2Coding'}
                    fontWeight={600}
                    sx={{ fontSize: 25, textShadow: '0px 2px 5px darkslategray', color: 'whitesmoke' }}
                >
                    {(remainingTimeRatio * 100).toFixed(2)}%
                </Typography>
            </CircularProgressbarWithChildren>
        </Box>
    );
}

export default RemainingLifeProgress