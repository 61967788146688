
import { Box, Typography } from '@mui/material'

interface LifetimeClockProp {
    // remainingHours: number
    // remainingMinutes: number
    // remainingSeconds: number
    totalRemainingLifespan: number
}

const LifeClock = ({ totalRemainingLifespan }: LifetimeClockProp) => {

    const d = 0;
    const h = Math.trunc(totalRemainingLifespan / 60 / 60) - (d * 24);
    const m = Math.trunc(totalRemainingLifespan / 60) - (h * 60) - (d * 24 * 60);
    const s = totalRemainingLifespan - (d * 24 * 60 * 60) - (h * 60 * 60) - (m * 60);


    return (
        <Box
            borderRadius={3}
            sx={{ backgroundColor: '#000000FF' }}
        >
            <Typography
                // fontFamily={'lcd-u'}
                fontFamily={'e1234'}
                fontSize={24}
                textAlign="center"
                lineHeight={1.8}
                paddingLeft={1}
                paddingRight={1}
                color={"lime"}
                sx={{
                    textShadow: '0px 0px 2px yellow'
                }}
            >
                {h}:{String(m).padStart(2, '0')}{s % 5 === 1 ? ' ' : ':'}{String(s).padStart(2, '0')}
            </Typography>
        </Box>);
}


export default LifeClock
