import { Typography } from "@mui/material";
import LifeSpanProp from "../../interfaces/LifeSpanProp";
import RunningMan from "../../imgs/tips/running_by_sporlab-min.jpg"
import LifeTipTemplate from "./LifeTipTemplate";

const description = (remainingHours: number) => {
    const age = (100 - remainingHours / 24 / 365);
    const speed = age < 60 ? 7.0 : 5.5;
    const totalDistance = Math.ceil(remainingHours / 24 * speed / 4);
    const roundTripNYtoSF = totalDistance / 5000.0;
    const roundTripSeoultoBusan = totalDistance / 860.0;
    const fromNYtoSF = <span>이건 미국 동부 뉴욕에서 부터 서부 샌프란시스코까지 <span className="result_key_numbers">{roundTripNYtoSF.toFixed(1)}번</span> 횡단하는 것과 같다고 합니다.</span>;
    const fromSeoultoBusan = <span>이건 서울에서 부터 부산까지 <span className="result_key_numbers">{roundTripSeoultoBusan.toFixed(1)}번</span> 왕복하는 것과 같다고 합니다.</span>;
    return (<Typography textAlign={"left"}>
        달리기는 정말 멋진 운동입니다. 오늘부터 살짝 숨이 찰 정도로 매일 15분씩 달려보세요. 100살 까지 달리는 총 거리는 무려 <span className="result_key_numbers">{totalDistance.toLocaleString()}km</span>나 됩니다.
        {roundTripNYtoSF > 2 ? fromNYtoSF : fromSeoultoBusan}
    </Typography >);
}

const Running = ({ remainingHours }: LifeSpanProp) => {
    return (
        <LifeTipTemplate
            tipName="running"
            description={description(remainingHours)}
            buttonDescription="달리기 코치를 만나보세요"
            buttonUrl="https://www.runday.co.kr/"
            image={RunningMan}
            imagePositionY="top" />
    );
};

export default Running;