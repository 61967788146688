
import { Box, Stack, Typography, Link, Paper, Divider } from "@mui/material";
import BirthdayTextField from "./BirthdayTextField";
import BirthdayProp from "../interfaces/BirthdaytProp";
import BigLogo from "./BigLogo";
import InfoModal from "./InfoModal";

const BirthdayPage = ({ birthday, birthdayChanger }: BirthdayProp) => {

    return (
        <Box maxWidth={450}
            minHeight="100vh"
            display="flex"
            flexDirection="column"
            alignItems="center"
            // justifyContent="center"
            paddingTop={4}
            paddingBottom={4}
        >
            <Box position={"absolute"} top={"100px"}>
                <BigLogo />
            </Box>
            <Box height={180}></Box>
            <Paper elevation={3}
                sx={{
                    background: 'rgba(255,255,255)',
                    padding: 2,
                    width: 300,
                    height: 250,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "center",
                    alignItems: "center"
                }}>

                <Typography
                    fontFamily={'D2Coding'}
                    fontSize={24}
                    fontWeight={600}
                    textAlign={"center"}
                    marginTop={2}
                    color={"lightslategray"}>
                    생일을 알려주세요
                </Typography>
                <Divider />
                <Box height={20}></Box>
                <BirthdayTextField birthdayChanger={birthdayChanger} birthday={birthday} />
            </Paper>
            <Typography variant="body2" color="whitesmoke" marginTop="50px" width="280px">
                입력하시는 생일을 비롯한 어떠한 개인정보도 서버에 저장하지 않습니다.
            </Typography>
        </Box>




    );
}
export default BirthdayPage  