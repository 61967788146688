import { Box, Button, Typography } from "@mui/material";
import { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import BirthdayProp from "../interfaces/BirthdaytProp";
import { useState } from "react";
import { validateBirthday } from "../utils/Birthday";
import ReactGA from "react-ga4";

const BirthdayTextField = ({ birthdayChanger }: BirthdayProp) => {

    const [newBirthday, setNewBirthday] = useState<Dayjs | null>(null);
    return (
        <Box display="flow" >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                    id="date_field_input"
                    variant="standard"
                    format="YYYY. MM. DD"
                    value={newBirthday}
                    onChange={(newValue) => {
                        setNewBirthday(newValue);
                    }}
                />
            </LocalizationProvider>
            <Box height={20}></Box>
            <Box margin={2}>
                <Button variant="contained"
                    fullWidth
                    onClick={() => {
                        if (newBirthday != null) {
                            if (validateBirthday(newBirthday)) {
                                ReactGA.event("submit_birthday", { "year": newBirthday.year() });
                                birthdayChanger(newBirthday);
                                window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth'
                                })
                            }
                        }
                    }}>
                    <Typography variant="body1">100세 생일까지 남은 시간 보기</Typography>
                </Button>
            </Box>
        </Box>
    );
}

export default BirthdayTextField