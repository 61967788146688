import './App.css';
import { Box, ThemeOptions, ThemeProvider, createTheme } from '@mui/material';
import ReactGA from "react-ga4";
import BirthdayPage from './components/BirthdayPage';
import Result from './components/Result';
import { useState } from 'react';
import dayjs from 'dayjs';
import InfoModal from './components/InfoModal';

// import black_hourglass from './imgs/black_hourglass.jpg'
import im12 from './imgs/backgrounds/_c7885d1a-7b72-44b3-9578-5b45401a7e9d.jpeg'
import im13 from './imgs/backgrounds/_d9380137-dbb5-4aa1-b4da-c4760b6fc3b2.jpeg'
import nd from './imgs/backgrounds/nathan-dumlao.jpg'
// 구글 애널리틱스 운영서버만 적용
if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
}

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#DB7093',
      // light: '#F9F871',
      // dark: '#845EC2',
      contrastText: '#F5F5F5'
    },
  },
  typography: {
    fontFamily: [
      "D2Coding", "Jose"
    ].join(',')
  },
};


function App() {
  const theme = createTheme(themeOptions);

  const defaultBirthday: dayjs.Dayjs | null = null;
  const [birthday, setBirthday] = useState<dayjs.Dayjs | null>(defaultBirthday);

  return (
    <ThemeProvider theme={theme}>
      <center>
        <Box position="fixed"
          // maxWidth={450}
          width={"100%"}
          height={"100vh"}
          left={"50%"}
          zIndex={-1}
          sx={{
            // backgroundColor: 'ivory',
            // backgroundColor: 'rgb(28,28,28)',
            background: `url(${nd})`,
            opacity: 0.25,
            backgroundSize: "cover",
            // backgroundSize: "contain",
            // backgroundRepeat: "no-repeat",
            backgroundPositionY: "bottom",
            transform: "translate(-50%)",
          }}
        >
        </Box>
        <Box>
          <InfoModal />
          {birthday === defaultBirthday ?
            <BirthdayPage birthday={birthday} birthdayChanger={setBirthday} />
            : <Result birthday={birthday} birthdayChanger={setBirthday} />}
        </Box>
      </center>
    </ThemeProvider >
  );
}

export default App;
