import { Box, Grid, Typography } from "@mui/material";

const BigLogo = () => {

    return (
        <Box textAlign="center">
            <Typography
                variant='h3'
                fontWeight={700}
                // color="palevioletred"
                color="whitesmoke"
                lineHeight={1.2}
                // fontFamily={'Amaranth'}
                id='app_title_text'
                sx={{ textShadow: "1px 1px 3px darkslategray" }}
            >
                100세<br />
                카운트다운!
            </Typography>
        </Box>
    );

};

export default BigLogo