import { Box, Link, Typography, IconButton, Dialog, DialogContent, DialogTitle, styled, Button, Avatar } from '@mui/material';
import { useState } from 'react';
import { CloseOutlined, InfoOutlined } from '@mui/icons-material';
import ReactGA from "react-ga4";
import profile_min from "../imgs/profile-min.png"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const InfoModal = () => {
    const [infoOpen, setInfoOpen] = useState(false);

    const handleClickOpen = () => {
        ReactGA.event("open_info_modal");
        setInfoOpen(true);
    };
    const handleClose = () => {
        setInfoOpen(false);
    };

    return (
        <Box maxWidth={"450px"} display="flex" justifyContent={"flex-end"}>
            <IconButton onClick={handleClickOpen}>
                <InfoOutlined sx={{ color: 'white' }} />
            </IconButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={infoOpen}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    100세 카운트다운!
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseOutlined />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        * 100세까지 남은 시간이 궁금해서 만들었습니다.
                    </Typography>
                    <Typography gutterBottom>
                        * 입력하시는 생일을 비롯한 어떠한 개인정보도 서버에 저장하지 않습니다.
                    </Typography>
                </DialogContent>
                <DialogContent>
                    <center>
                        <Avatar alt="Cosmos Galaxy Pub" src={profile_min} sx={{ width: 72, height: 72 }} />
                        <Typography variant="body2">
                            <Button href="https://cosmos-galaxy-pub.notion.site" target="_blank">Made by 우주술집:cosmos-galaxy-pub</Button>
                        </Typography>
                        {/* <Button href="https://forms.gle/yUNiDsUUejdyAEVy6" target="_blank" variant='outlined'>광고문의</Button> */}
                    </center>
                </DialogContent>
            </BootstrapDialog>
        </Box>
    )
}

export default InfoModal