import { Box, Divider, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import BirthdayProp from "../interfaces/BirthdaytProp";
import { remainingLifespan } from "../utils/Birthday";
import 'react-circular-progressbar/dist/styles.css';
import LifeClock from "./LifeClock";
import RemainingLifeProgress from "./RemainingLifeProgress";



const LifeSpanCountdown = ({ birthday, birthdayChanger }: BirthdayProp) => {
    const theLastDay = birthday?.add(100, "year");
    const [totalRemainingLifespan, setTotalRemainingLifespan] = useState(remainingLifespan(theLastDay));
    const [remainingTimeRatio, setRemainingTimeRatio] = useState(totalRemainingLifespan / (36525 * 24 * 60 * 60));

    useEffect(() => {
        const id = setInterval(() => setTotalRemainingLifespan((totalRemainingLifespan) => totalRemainingLifespan - 1), 1000);
        return () => {
            clearInterval(id);
        };
    }, []);

    useEffect(() => {
        setRemainingTimeRatio(totalRemainingLifespan / (36525 * 24 * 60 * 60));
    }, [totalRemainingLifespan]);

    return (
        <Paper elevation={3}
            sx={{
                background: 'rgba(255,255,255)',
                padding: 2,
                width: 300,
                height: 250,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "center",
                alignItems: "center"
            }}>
            <Typography
                fontFamily={'D2Coding'}
                fontSize={24}
                fontWeight={600}
                textAlign={"center"}
                marginTop={2}
                color={"lightslategray"}>
                앞으로 남은 시간
            </Typography>
            <Divider></Divider>
            <Box height={20}></Box>
            <RemainingLifeProgress remainingTimeRatio={remainingTimeRatio} />
            <LifeClock totalRemainingLifespan={totalRemainingLifespan} />
        </Paper>
    );
}

export default LifeSpanCountdown