import { Box, Button, Grid, Paper } from "@mui/material";
import LifeTipProp from "../../interfaces/LifeTipProp";
import ReactGA from "react-ga4";


const LifeTipTemplate = ({ tipName, description, buttonDescription, buttonUrl, image, imagePositionY }: LifeTipProp) => {

    return (
        <Paper elevation={3}
            sx={{
                background: 'rgba(255,255,255,0.85)',
                padding: 2,
                width: 300
            }}
        >

            <Grid container
                justifyContent="center"
                alignItems="center"
                spacing={1}
                rowSpacing={3}>
                <Grid item xs={12}>
                    <Box
                        height={150}
                        width={296}
                        borderRadius={1}
                        sx={{
                            background: `url(${image})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPositionY: `${imagePositionY}`
                        }}
                    >
                    </Box>
                </Grid>
                <Grid item xs={12} >
                    {description}
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => {
                        ReactGA.event("click_tip", { "tip": tipName });
                        window.open(buttonUrl, "_blank");
                    }}>
                        {buttonDescription}
                    </Button>
                </Grid>
            </Grid>
        </Paper >
    )
};

export default LifeTipTemplate;